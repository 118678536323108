@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';
@font-face {
  font-family: 'SB Sans Interface';
  src: url('/assets/fonts/SBSansInterface-Semibold.woff2') format('woff2'),
  url('/assets/fonts/SBSansInterface-Semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SB Sans Interface';
  src: url('/assets/fonts/SBSansInterface-Regular.woff2') format('woff2'),
  url('/assets/fonts/SBSansInterface-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SB Sans Interface Caps';
  src: url('/assets/fonts/SBSansInterface-Caps.woff2') format('woff2'),
  url('/assets/fonts/SBSansInterface-Caps.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SB Sans Interface';
  src: url('/assets/fonts/SBSansInterface-Light.woff2') format('woff2'),
  url('/assets/fonts/SBSansInterface-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SB Sans Interface';
  src: url('/assets/fonts/SBSansInterface-Bold.woff2') format('woff2'),
  url('/assets/fonts/SBSansInterface-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: SBSansInterface, Inter, 'Helvetica Neue', Helvetica, Arial, sans-serif;
}
